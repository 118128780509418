import useWindowSize from "../../../hooks/useWindowSize";
import useValue from "../../../hooks/useValue";
import {Grid, Row} from "@nextui-org/react";
import FooterInfoBlock from "./FooterInfoBlock";
import {useMemo} from "react";
import {IconBrandTelegram, IconBrandVk} from "@tabler/icons-react";
import {Socials} from "./Socials";

const FooterInformation = ({blocks, help}) => {
    const windowSize = useWindowSize();
    const mobileVersion = windowSize.width < 1280 || windowSize.height < 700;
    const currentExpanded = useValue("")

    const {helpItems, additionalHelpItems} = useMemo(() => {
        const additionalHelpItems = help.map(item => ({text: item.title, link: item.url}));
        const helpItems = additionalHelpItems.splice(0, 5);

        return {additionalHelpItems: additionalHelpItems, helpItems: helpItems}
    }, [help])

    const onOpen = id => {
        if (currentExpanded.value === id) {
            currentExpanded.set("");
            return;
        }

        currentExpanded.set(id);
    }

    return (
        <Grid.Container>
            <FooterInfoBlock
                title={"Контакты"}
                items={[
                    {text: "Касса: 8 (861) 373-20-40"},
                    {text: "Почта: arm_teatr@inbox.ru"},
                    {row: <Socials/>},
                ]}
                mobileVersion={mobileVersion}
                isOpen={currentExpanded.value === "contacts"}
                onOpen={() => onOpen("contacts")}
            />
            <FooterInfoBlock
                title={"Касса"}
                items={[
                    {text: "ПН: выходной"},
                    {text: "ВТ: 10:30 - 14:00"},
                    {text: "СР-ВС: 10:30 - 18:00"},
                    {text: "Перерыв: 14:00 - 15:00"},
                ]}
                mobileVersion={mobileVersion}
                isOpen={currentExpanded.value === "time"}
                onOpen={() => onOpen("time")}
            />
            <FooterInfoBlock
                title={"Адрес"}
                items={[
                    {text: "Краснодарский край"},
                    {text: "г. Армавир, ул. Ленина, д. 84"},
                ]}
                mobileVersion={mobileVersion}
                isOpen={currentExpanded.value === "address"}
                onOpen={() => onOpen("address")}
            />
            {help.length !== 0 &&
                <FooterInfoBlock
                    title={"Помощь"}
                    items={helpItems}
                    additionalItems={mobileVersion ? additionalHelpItems : null}
                    mobileVersion={mobileVersion}
                    isOpen={currentExpanded.value === "help"}
                    onOpen={() => onOpen("help")}
                />
            }
            {!mobileVersion && help.length !== 0 &&
                <FooterInfoBlock
                    title={""}
                    items={additionalHelpItems}
                    mobileVersion={mobileVersion}
                />
            }

        </Grid.Container>
    )
        ;
}

export default FooterInformation;